import React, { useState } from "react";
import "./Header.scss";

import { Menu } from "antd";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Logo } from "../../asstes";
import { InstagramOutlined, FacebookOutlined } from "@ant-design/icons";
const items = [
  {
    label: "RISTORANTE",
    // icon: <MailOutlined />,
    key: "/",
  },
  {
    label: "MENU",
    // icon: <AppstoreOutlined />,
    key: "/menu",
  },
];
const Header = () => {
  const location = useLocation();
  const [current, setCurrent] = useState(location?.pathname || "/");
  const navigate = useNavigate();
  const outlet = useOutlet();

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e?.key);
  };

  return (
    <>
      <div className="header">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={Logo} alt="logo" width={120} height={70} />
        </div>
        <div className="menuContainer">
          <Menu
            style={{ width: "100%" }}
            // style={{ height: 70 }}
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          />
        </div>
        <div className="iconHeader">
          <a
            href="https://www.instagram.com/doncarlos.ristorante/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramOutlined
              style={{ color: "#002244", cursor: "pointer" }}
            />
          </a>
          <a
            href="https://www.facebook.com/don.carlos.186"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookOutlined style={{ color: "#002244", cursor: "pointer" }} />
          </a>
        </div>
      </div>
      {outlet}
      <Footer />
    </>
  );
};
export default Header;
